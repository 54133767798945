<template>
    <b-badge
        variant="primary"
        class="d-flex align-items-center align-content-center mr-2 mb-2 p-2"
    >
        <div>{{applicationText}} {{version}}</div>
        <div class="ml-2">
            <b-btn
                variant="light"
                size="sm"
                class="text-danger"
                @click.prevent="onRemove"
            >
                <b-icon icon="trash"></b-icon>
            </b-btn>
        </div>
    </b-badge>
</template>

<script>
export default {
    name: 'SoftwareBadge',
    props: {
        application: {
            type: String,
            required: true
        },
        version: {
            type: String,
            required: true
        },
        options: {
            type: Array,
            required: false,
            default () {
                return []
            }
        }
    },
    computed: {
        applicationText () {
            const text = this.options.filter(item => `${item.value}` === `${this.application}`)
            if (text.length > 0) {
                return text[0].text
            }
            return this.application
        }
    },
    methods: {
        onRemove () {
            this.$emit('remove')
        }
    }
}
</script>
