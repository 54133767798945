<template>
    <b-form
        @submit.prevent="onSubmit"
        class="form-sm"
    >
        <multiselect-editor
            v-model="nativeSpeakerLanguages"
            :id="'propertyLists.nativeSpeakerLanguages'"
            :heading="$t('propertyLists.nativeSpeakerLanguages')"
            :validation="$v.nativeSpeakerLanguages"
            :options="languageOptions"
            :label="$t(`propertyLists.${type}NativeSpeakerLanguagesHint`)"
            @changed="onChanged"
            :max-items="(type === 'person') ? 1 : undefined"
        />

        <multiselect-editor
            class="mt-4"
            v-model="specializations"
            :id="'propertyLists.specializations'"
            :heading="$t('propertyLists.specializations')"
            :validation="$v.specializations"
            :options="specializationOptions"
            :label="$t(`propertyLists.${type}SpecializationsHint`)"
            :max-items="2"
            @changed="onChanged"
        />

        <multiselect-editor
            class="mt-4"
            v-model="forms"
            :id="'propertyLists.forms'"
            :heading="$t('propertyLists.forms')"
            :options="formOptions"
            :label="$t('propertyLists.formsHint')"
            @changed="onChanged"
            :validation="$v.forms"
        />

        <select-software-editor
            class="mt-4"
            v-model="software"
            :id="'propertyLists.software'"
            :heading="$t('propertyLists.software')"
            :options="softwareOptions"
            :label="$t('propertyLists.softwareHint')"
            @changed="onChanged"
        />

        <select-software-editor
            class="mt-4"
            v-model="catTools"
            :id="'propertyLists.catTools'"
            :heading="$t('propertyLists.catTools')"
            :options="catToolsOptions"
            :label="$t('propertyLists.catToolsHint')"
            @changed="onChanged"
        />

        <div class="mt-5 row">
            <div class="col-12">
                <h4>{{$t('propertyLists.additionalData')}}</h4>
            </div>
            <div class="col-12 mb-4" v-if="type === 'company'">
                <select-certification-editor
                    v-model="certifications"
                    :id="'propertyLists.certifications'"
                    :options="certificationOptions"
                    :label="$t('propertyLists.certificationsHint')"
                    @changed="onChanged"
                />
            </div>
        </div>

        <div class="row" v-if="type === 'person'">
            <div class="col-12 col-md-6 col-lg-4">
                <form-input-select
                    v-model="education"
                    :id="'propertyLists.education'"
                    :label="$t('propertyLists.education')"
                    :validation="$v.education"
                    :options="educationOptions"
                />
            </div>

            <div class="col-12 mb-4">
                <multiselect-editor
                    class="mt-4"
                    v-model="educationInField"
                    :id="'propertyLists.educationInField'"
                    :label="$t('propertyLists.educationInField')"
                    :validation="$v.educationInField"
                    :options="educationInFieldOptions"
                    @changed="onChanged"
                />

                <multiselect-editor
                    class="mt-4"
                    v-model="employmentInField"
                    :id="'propertyLists.employmentInField'"
                    :label="$t('propertyLists.employmentInField')"
                    :validation="$v.employmentInField"
                    :options="employmentInFieldOptions"
                    @changed="onChanged"
                />
            </div>

            <div class="col-12 col-md-6 col-lg-4">
                <form-input
                    v-model="experienceFrom"
                    :id="'propertyLists.experienceFrom'"
                    :label="$t('propertyLists.experienceFrom')"
                    :formatter="yearFormatter"
                    :validation="$v.experienceFrom"
                    :show-as-required="false"
                />
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <form-input
                    v-model="fullTimeJobFrom"
                    :id="'propertyLists.fullTimeJobFrom'"
                    :label="$t('propertyLists.fullTimeJobFrom')"
                    :formatter="yearFormatter"
                    :hint="$t('propertyLists.fullTimeJobFromHint')"
                    :validation="$v.fullTimeJobFrom"
                    :show-as-required="false"
                />
            </div>
        </div>

        <div class="mt-4 text-right">
            <aspena-btn
                class="mr-3"
                variant="aspena-blue"
                @click.prevent="onCancel"
            >
                {{$t('basicData.cancel')}}
            </aspena-btn>

            <aspena-btn
                class="mr-3"
                variant="aspena-orange"
                type="submit"
            >
                {{$t('basicData.submit')}}
            </aspena-btn>
        </div>
    </b-form>
</template>

<script>
import { PropertyLists } from '../../../DTO'
import { required, numeric, minValue, maxValue, helpers } from 'vuelidate/lib/validators'
import MultiselectEditor from './MultiselectEditor.vue'
import SelectSoftwareEditor from './SelectSoftwareEditor.vue'
import SelectCertificationEditor from './SelectCertificationEditor.vue'

export default {
    name: 'PropertiesListForm',
    components: {
        MultiselectEditor,
        SelectSoftwareEditor,
        SelectCertificationEditor
    },
    props: {
        type: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            ...((new PropertyLists(this.$store.state.userData.propertyLists || {})).toObject())
        }
    },
    computed: {
        languageOptions () {
            const options = JSON.parse(JSON.stringify(this.$store.state.enums.languageOptions || []))
            options.sort((a, b) => a.text.localeCompare(b.text, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
            return options
        },
        specializationOptions () {
            const options = JSON.parse(JSON.stringify(this.$store.state.enums.specializationOptions || []))
            options.sort((a, b) => a.text.localeCompare(b.text, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
            return options
        },
        formOptions () {
            const options = JSON.parse(JSON.stringify(this.$store.state.enums.formOptions || []))
            options.sort((a, b) => a.text.localeCompare(b.text, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
            return options
        },
        softwareOptions () {
            const options = JSON.parse(JSON.stringify(this.$store.state.enums.softwareOptions || []))
            options.sort((a, b) => a.text.localeCompare(b.text, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
            return options
        },
        catToolsOptions () {
            const options = JSON.parse(JSON.stringify(this.$store.state.enums.catToolsOptions || []))
            options.sort((a, b) => a.text.localeCompare(b.text, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
            return options
        },
        certificationOptions () {
            const options = JSON.parse(JSON.stringify(this.$store.state.enums.certificationOptions || []))
            options.sort((a, b) => a.text.localeCompare(b.text, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
            return options
        },
        educationOptions () {
            return JSON.parse(JSON.stringify(this.$store.state.enums.educationOptions || []))
        },
        educationInFieldOptions () {
            const options = JSON.parse(JSON.stringify(this.$store.state.enums.educationInFieldOptions || []))
            options.sort((a, b) => a.text.localeCompare(b.text, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
            return options
        },
        employmentInFieldOptions () {
            const options = JSON.parse(JSON.stringify(this.$store.state.enums.employmentInFieldOptions || []))
            options.sort((a, b) => a.text.localeCompare(b.text, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
            return options
        }
    },
    methods: {
        yearFormatter (value) {
            return `${value}`.replace(/\D/ig, '')
        },
        onChanged () {
            const data = new PropertyLists(JSON.parse(JSON.stringify(this.$data)))
            this.$store.commit('mergeData', data)
        },
        onSubmit () {
            if (this.$v.$invalid) {
                this.$notify.error(this.$t('propertyLists.formInvalidMessage'))
                return this.$v.$touch()
            }
            const data = new PropertyLists(JSON.parse(JSON.stringify(this.$data)))
            this.$store.commit('mergeData', data)
            this.$emit('submit', data)
        },
        onCancel () {
            this.$emit('cancel')
        }
    },
    validations () {
        return {
            nativeSpeakerLanguages: {
                required
            },
            specializations: {
                required
            },
            forms: {
                required
            },
            ...(
                (this.type === 'person')
                    ? {
                        experienceFrom: {
                            numeric: (value) => !helpers.req(value) || numeric(value),
                            minValue: (value) => !helpers.req(value) || minValue(1900)(value),
                            maxValue: (value) => !helpers.req(value) || maxValue((new Date()).getFullYear())(value)
                        },
                        fullTimeJobFrom: {
                            numeric,
                            minValue: minValue(1900),
                            maxValue: maxValue((new Date()).getFullYear())
                        },
                        education: {
                            required
                        },
                        employmentInField: {
                            required
                        }
                    } : {}
            )
        }
    }
}
</script>
