<template>
    <div class="row">
        <div class="col-12" v-if="heading">
            <h4>
                {{heading}}
            </h4>
        </div>
        <div class="col-12 col-md-6 mb-4 mb-md-0">
            <form-input-select
                class="mb-2"
                :options="options"
                :label="label"
                :id="id"
                :hint="hint"
                v-model="certificate"
                :validation="$v.certificate"
                :show-as-required="false"
            />
            <form-input-date-picker
                :label="$t('propertyLists.fromDate')"
                :id="`${id}.fromDate`"
                v-model="computedFromDate"
                :max-date="maxDate"
                :button-only="true"
                :date-placeholder="$t('datePlaceholder')"
                :show-as-required="false"
                :validation="$v.fromDate"
            />
            <p>
                <aspena-btn
                    size="sm"
                    variant="aspena-orange"
                    @click.prevent="onAdd"
                >
                   {{$t('multiselect.add')}}
                </aspena-btn>
            </p>
        </div>
        <div class="col-12 col-md-6">
            <p class="text-bold text-aspena-blue mb-2">
                {{$t('multiselect.selectedHeading')}}
            </p>
            <p class="alert alert-primary" v-if="modelValue.length === 0 && (!validation || !validation.$invalid)">
                {{$t('multiselect.nothingSelected')}}
            </p>
            <p class="alert alert-danger" v-if="modelValue.length === 0 && validation && validation.$invalid">
                {{$t((this.maxItems === 1) ? 'multiselect.selectOne' : 'multiselect.selectAtLeastOne')}}
            </p>
            <p class="d-flex flex-wrap" v-if="modelValue.length > 0">
                <software-badge
                    v-for="(item, index) in modelValue"
                    :key="`${item.certificate}${item.fromDate}${index}`"
                    :options="options"
                    :application="item.certificate"
                    :version="`${$t('propertyLists.from')} ${(new Date(item.fromDate)).toLocaleDateString()}`"
                    @remove="onRemove(index)"
                />
            </p>
        </div>
    </div>
</template>

<script>
import { FormInputSelect } from '@tvaliasek/vue-form-inputs'
import { required } from 'vuelidate/lib/validators'
import SoftwareBadge from './SoftwareBadge.vue'

export default {
    components: { SoftwareBadge },
    name: 'SelectCertificationEditor',
    extends: FormInputSelect,
    props: {
        heading: {
            type: String,
            required: false
        }
    },
    data () {
        return {
            certificate: null,
            fromDate: null
        }
    },
    computed: {
        maxDate () {
            const date = new Date()
            date.setHours(12, 0, 0, 0)
            date.setDate(date.getDate() + 1)
            return date
        },
        computedFromDate: {
            get () {
                return this.fromDate
            },
            set (value) {
                if (value instanceof Date) {
                    this.fromDate = value
                } else if (typeof value === 'string') {
                    const tmp = new Date(value)
                    if (!isNaN(tmp.valueOf())) {
                        this.fromDate = value
                    }
                }
            }
        },
        modelValue: {
            get () {
                return this.value || []
            },
            set (value) {
                this.$emit('input', [...new Set(value)])
            }
        }
    },
    methods: {
        onRemove (index) {
            this.modelValue = this.modelValue.filter((entry, entryIndex) => entryIndex !== index)
            this.$nextTick(() => {
                this.$emit('changed')
            })
        },
        onAdd () {
            if (this.$v.$invalid) {
                return this.$v.$touch()
            }
            this.modelValue.push({
                certificate: this.certificate,
                fromDate: this.fromDate
            })

            this.$nextTick(() => {
                this.certificate = null
                this.fromDate = null
                this.$emit('changed')
                this.$nextTick(() => {
                    this.$v.$reset()
                })
            })
        }
    },
    validations: {
        certificate: {
            required
        },
        fromDate: {
            required
        }
    }
}
</script>
