<template>
    <div class="row">
        <div class="col-12" v-if="heading">
            <h4>
                {{heading}}
            </h4>
        </div>
        <div class="col-12 col-md-6 mb-4 mb-md-0">
            <form-input-select
                class="mb-2"
                :options="options"
                :label="label"
                :id="id"
                :hint="hint"
                v-model="application"
                :validation="$v.application"
                :show-as-required="false"
            />
            <form-input
                :label="$t('propertyLists.softwareVersion')"
                :id="`${id}.softwareVersion`"
                v-model="version"
                :validation="$v.version"
                :show-as-required="false"
                :hint="$t('propertyLists.softwareVersionHint')"
            />
            <p>
                <aspena-btn
                    size="sm"
                    variant="aspena-orange"
                    @click.prevent="onAdd"
                >
                   {{$t('multiselect.add')}}
                </aspena-btn>
            </p>
        </div>
        <div class="col-12 col-md-6">
            <p class="text-bold text-aspena-blue mb-2">
                {{$t('multiselect.selectedHeading')}}
            </p>
            <p class="alert alert-primary" v-if="modelValue.length === 0 && (!validation || !validation.$invalid)">
                {{$t('multiselect.nothingSelected')}}
            </p>
            <p class="alert alert-danger" v-if="modelValue.length === 0 && validation && validation.$invalid">
                {{$t((this.maxItems === 1) ? 'multiselect.selectOne' : 'multiselect.selectAtLeastOne')}}
            </p>
            <p class="d-flex flex-wrap" v-if="modelValue.length > 0">
                <software-badge
                    v-for="(item, index) in modelValue"
                    :key="`${item.application}${item.version}${index}`"
                    :application="item.application"
                    :version="item.version"
                    :options="options"
                    @remove="onRemove(index)"
                />
            </p>
        </div>
    </div>
</template>

<script>
import { FormInputSelect } from '@tvaliasek/vue-form-inputs'
import { required } from 'vuelidate/lib/validators'
import SoftwareBadge from './SoftwareBadge.vue'

export default {
    name: 'SelectSoftwareEditor',
    extends: FormInputSelect,
    components: {
        SoftwareBadge
    },
    props: {
        heading: {
            type: String,
            required: false
        }
    },
    data () {
        return {
            application: null,
            version: null
        }
    },
    computed: {
        modelValue: {
            get () {
                return this.value || []
            },
            set (value) {
                this.$emit('input', [...new Set(value)])
            }
        }
    },
    methods: {
        onRemove (index) {
            this.modelValue = this.modelValue.filter((entry, entryIndex) => entryIndex !== index)
            this.$nextTick(() => {
                this.$emit('changed')
            })
        },
        onAdd () {
            if (this.$v.$invalid) {
                return this.$v.$touch()
            }
            this.modelValue.push({
                application: this.application,
                version: this.version
            })

            this.$nextTick(() => {
                this.application = null
                this.version = null
                this.$emit('changed')
                this.$nextTick(() => {
                    this.$v.$reset()
                })
            })
        }
    },
    validations: {
        application: {
            required
        },
        version: {
            required
        }
    }
}
</script>
